import { create } from 'zustand';

export const useEventStore = create((set, get) => ({
  events: [
    {
      id: '696910',
      name: 'TechXcelerate 3.0 - Project Submitted',
      date: '2024-10-14',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1733108761/achievement_rij3pq.png',
      namePosition: {
        x: 1010,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        "jeetsarkar1st353@gmail.com": "Jeet Sarkar",
        "anirbanghosh060@gmail.com": "Anirban Ghosh",
        "lohitkolluri@gmail.com": "Lohit Kolluri",
        "22211a6697@bvrit.ac.in": "Ujwala Rapalli",
        "mailtoajay7007@gmail.com": "Ajay Kumar",
        "bighneshkumarsahoo58@gmail.com": "Bighnesh Sahoo",
        "suhas4497@gmail.com": "Suhas N H",
        "soumyadiproy894@gmail.com": "Soumyadip Roy",
        "prishag407@gmail.com": "Prisha Gupta",
        "adityatiwari4125@gmail.com": "Aditya Tiwari",
        "aliahaider149@gmail.com": "Alia Haider",
        "mayankg88813@gmail.com": "Mayank Gupta",
        "vasvi.chopra30@gmail.com": "Vasvi Chopra",
        "sajiaashfaq@gmail.com": "Sajia Ashfaq",
        "hedaumithanshu@gmail.com": "Mithanshu Hedau",
        "adityarajkaran6631@gmail.com": "Aditya RajKaran",
        "hiyatrehan@gmail.com": "Hiya Trehan",
        "sharmavani440@gmail.com": "Vani Sharma",
        "itsvanimoksh888@gmail.com": "Vani Jain",
        "avantika.adgips369@gmail.com": "Avantika Padhi",
        "rathorepiyush0000@gmail.com": "Piyush Rathore",
        "priyachaurasia2006@gmail.com": "Priya Chaurasia",
        "sushmasrichanda03@gmail.com": "Sushmasri Chanda",
        "vansh20005@gmail.com": "Vansh Shandilya",
        "anilkumarxc03@gmail.com": "Anil Kumar",
        "99krish.singla@gmail.com": "Krish Singla",
        "sandrarosaprince@gmail.com": "Sandra Prince",
        "plpanjali05@gmail.com": "Lakshmi Pavananjali Pamidi",
        "harshitabidhuri625@gmail.com": "Harshita Bidhuri",
        "diyagahlot2006@gmail.com": "Diya Gahlot",
        "jaingaurav906@gmail.com": "Gaurav Jain",
        "umairahmedcontent@gmail.com": "Umair Ahmad",
        "akhilalohidakshan@gmail.com": "Akhila Lohidakshan",
        "akashmaji200680@gmail.com": "Supriyo Maji",
        "nr.neha9990@gmail.com": "Neha Rawat",
        "arushinirala458@gmail.com": "Arushi Nirala",
        "2006arnavsharma@gmail.com": "Arnav Sharma",
        "gauravjoshi7069@gmail.com": "Gaurav Joshi",
        "ashkumar2909@gmail.com": "Ashish Kumar Mahto",
        "mansisinghal623@gmail.com": "Mansi",
        "payalkhaterjain@gmail.com": "Mudit Khater",
        "rakshitgoyal770@gmail.com": "Rakshit Goyal",
        "sampad.sg.cse@gmail.com": "Sampad Gorai",
        "sakshamtyagi2008@gmail.com": "Saksham Tyagi",
        "crajdadhich05@gmail.com": "Chirag Raj",
        "psrcofficial10@gmail.com": "Pradeepto Sarkar",
      },
    },
    {
      id: '696901',
      name: 'TechXcelerate 3.0 - Web Development',
      date: '2024-10-14',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733588/webdev_y2b7xo.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        'aggarwalkavya2005@gmail.com': 'Kaavy Aggarwal',
        'aryan1234guptaa@gmail.com': 'Aryan',
        'deepanshukashyap8299@gmail.com': 'DEEPANSHU KASHYAP',
        'aanyagoyal45@gmail.com': 'Aanya Goyal',
        'mayankarora2302@gmail.com': 'Mayank Arora',
        'asifqamar1832004@gmail.com': 'Asif Qamar',
        'anjalishukla3007@gmail.com': 'Anjali Shukla',
        'Guptadivyansh357@gmail.com': 'Divyansh Gupta',
        'sumit.2428cse1842@kiet.edu': 'Sumit Pandey',
        'aayushgautam2502@gmail.com': 'Aayush Gautam',
        'mishka160506@gmail.com': 'Mishka',
        'piyusharora1307@gmail.com': 'Piyush Arora',
        'abhikumarsharma146@gmail.com': 'Abhishek Kumar Sharma',
        'nawazbiyawarwala@gmail.com': 'Nawaz Biyawarwala',
        'samartharora2004@gmail.com': 'Samarth Arora',
        'anmolmeetsingh010@gmail.com': 'Anmolmeet Singh',
        'alokrathore136@gmail.com': 'Alok Pratap Singh Rathor',
        'amriteshkumarrai14@gmail.com': 'Amritesh Kumar Rai',
        'aggarwalananya081@gmail.com': 'Ananya Aggarwal',
        'amanverdiya06@gmail.com': 'Aman',
        'vaibhavkathait5974@gmail.com': 'Vaibhav Kathait',
        'vansh20005@gmail.com': 'Vansh Shandilya',
        'Chiragk1412@gmail.com': 'Chirag Kashyap',
        'kingarjun74280@gmail.com': 'Arjun',
        'uditjain885@gmail.com': 'Udit Jain',
        'Sanketsagar727@gmail.com': 'Sanket Sagar',
        'work.anshikaj@gmail.com': 'ANSHIKA',
        'college.studying23@gmail.com': 'Lavkush',
        'kanojiaparas582@gmail.com': 'Paras',
        'bhardwajpriyanshi6@gmail.com': 'Priyanshi',
        'warhero9838@gmail.com': 'Anupam Singh',
        'parthg1304@gmail.com': 'Parth Gupta',
        'avnigoel.2005@gmail.com': 'Avni Goel',
        'guptamahima583@gmail.com': 'Mahima Gupta',
        'deepansh2428@gmail.com': 'DEEPANSHU SAXENA',
        'agrim.816@gmail.com': 'Agrim Sharma',
        'aditya.23gcebaids009@galgotia.edu': 'Aditya Kumar',
        'kaurmanmeet2811@gmail.com': 'Manmeet Kaur',
        'Pandeyishaan70@gmail.com': 'Ishan Pandey',
        'kanwatanirudh@gmail.com': 'Anirudh Kanwat',
        'manavgarg2643@gmail.com': 'MANAV GARG',
        'dhairya.summi@gmail.com': 'Dhairya Summi',
        'mishra.akanksha535@gmail.com': 'Akanksha Kumari Mishra',
        'singh.adityakumar8318@gmail.com': 'Aditya Kumar',
        'ekhaliq490@gmail.com': 'Eman Khaliq',
        'vivekkumar843331@gmail.com': 'Vivek Kumar',
        'earthianprithviwasi8888@gmail.com': 'Earthian Prithviwasi',
        '99krish.singla@gmail.com': 'Krish Singla',
        'sarthakkr00005@gmail.com': 'Sarthak Kumar',
        'sushmasrichanda03@gmail.com': 'Chanda Sushmasri',
        '1rn21ai126.sujayd@gmail.com': 'Sujay D',
        '0241csai214@niet.co.in': 'Ambuj Bhatt',
        'akshadtsharma@gmail.com': 'Akshat Sharma',
        'itsvanimoksh888@gmail.com': 'Vani Jain',
        'sharmabhumika297@gmail.com': 'Bhumika Sharma',
        'krishjatin02@gmail.com': 'Krish Gupta',
        'md5077n@gmail.com': 'Madhav Nanda',
        'chhavissjha@gmail.com': 'Chhavi Jha',
        'sandrarosaprince@gmail.com': 'Sandra Rosa Prince',
        'mishrasahil934@gmail.com': 'Sahil Mishra',
        'sparshaggarwal0308@gmail.com': 'Sparsh Aggarwal',
        'krishjaidka9@gmail.com': 'Krish Jaidka',
        'madsharma0022@gmail.com': 'Madhav Sharma',
        'aditya342me@gmail.com': 'Aditya Menon',
        'sahil.kr9205@gmail.com': 'Sahil Kumar',
        'anilkumarxc03@gmail.com': 'Anil Kumar',
        'asthashukla0308@gmail.com': 'Astha Shukla',
        'gauravjoshi7069@gmail.com': 'Gaurav Joshi',
        'chhavissjha@gmail.com': 'Chhavi Jha',
        'nakulgupta0152@gmail.com': 'Nakul Gupta',
        'khushi.s.222613@gmail.com': 'Khushi Singh',
        'rathordisha62@gmail.com': 'Disha Rathore',
        'kosangyadav2004@gmail.com': 'Kosang Yadav',
        'ashkumar2909@gmail.com': 'Ashish Kumar Mahto',
        'aditya00435@gmail.com': 'Aditya Kumar Jha',
        'anantikaasingh00@gmail.com': 'Anantika Singh Kushwaha',
        'Skk216911@gmail.com': 'Sumit Kumar',
        'dbrushstokes@gmail.com': 'Samriddhi Dua',
        'raghavbansal1981@gmail.com': 'Raghav Bansal',
        'saharshsharma28@gmail.com': 'Saharsh Sharma',
        'hardikbindal12@gmail.com': 'Hardik Bindal',
        'krishgoyal180204@gmail.com': 'Krish Goyal',
        'shwetanksingh2304@gmail.com': 'Shwetank Singh',
        'prathamehra2003@gmail.com': 'Pratham Mehra',
        'harshilgupta27079@gmail.com': 'Harshil Gupta',
        'daumyag@gmail.com': 'Daumya Gupta',
        'anshkumar5008@gmail.com': 'Ansh Kumar',
        'nlshkore@gmail.com': 'Nilesh Kore',
        'riya.m5780@gmail.com': 'Riya Mehta',
        'chittanshkumarsingh@gmail.com': 'Chittansh Kumar',
        'karishma674singh@gmail.com': 'Karishma',
        'pari746187@gmail.com': 'Pari',
        'dakshshastri72@gmail.com': 'Daksh Shastri',
        'srishtisinghal6125@gmail.com': 'Srishti Singhal',
        'Jasmeetanand05@gmail.com': 'Jasmeet Anand',
        'harmandeepsingh1008@gmail.com': 'Harmandeep Singh',
        'anshitsharma182@gmail.com': 'Anshit Sharma',
        'aakritiarya2005@gmail.com': 'Aakriti Arya',
        'h.e.m.a.n.t.xyzz@gmail.com': 'HEMANT',
        'sarthaksharma991145@gmail.com': 'SARTHAK',
        'vansh20175014@gmail.com': 'Vansh Garg',
        'muditkhater99@gmail.com': 'Mudit Khater',
        'jainaditya16106@gmail.com': 'Aditya Jain',

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696902',
      name: 'TechXcelerate 3.0 - AI and ML',
      date: '2024-10-15',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733580/aiml_k5tiro.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        '9riday@gmail.com': 'Hriday Aggarwal',
        'sarthak.molu08@gmail.com': 'Sarthak',
        'samartharora2004@gmail.com': 'Samarth Arora',
        'sumaiyahafiz7@gmail.com': 'Sumaya Hashim',
        'work.anshikaj@gmail.com': 'ANSHIKA',
        'sparshaggarwal0308@gmail.com': 'Sparsh Aggarwal',
        'khushi.s.222613@gmail.com': 'Khushi Singh',
        'pratyushjha06@gmail.com': 'Pratyush Jha',
        'bhardwajpriyanshi6@gmail.com': 'Priyanshi',
        'deepanshukashyap8299@gmail.com': 'DEEPANSHU KASHYAP',
        '2024pietcsbhumika037@poornima.org': 'Bhumika Sharma',
        'anantikaasingh00@gmail.com': 'Anantika Singh Kushwaha',
        'tyagikanishka1864@gmail.com': 'Kanishka Tyagi',
        'arushinirala458@gmail.com': 'Arushi Nirala',
        'earthianprithviwasi8888@gmail.com': 'Earthian Prithviwasi',
        'jhasabhishek17@gmail.com': 'Abhishek Jha',
        'sonkarshashwat05@gmail.com': 'Shashwat Asar Sonkar',
        'krishgoyal180204@gmail.com': 'Krish Goyal',
        'harshdorle6@gmail.com': 'Swarwel Dorle',
        'sandrarosaprince@gmail.com': 'Sandra Rosa Prince',
        'sarthakkr00005@gmail.com': 'Sarthak Kumar',
        '99krish.singla@gmail.com': 'Krish Singla',
        'chhavissjha@gmail.com': 'Chhavi Jha',
        'rbudhori04@gmail.com': 'Richa Budhori',
        'priyachaurasia.work@gmail.com': 'Priya Chaurasia',
        'jainsomya184@gmail.com': 'Somya Jain',
        'madsharma0022@gmail.com': 'Madhav Sharma',
        'vansh20005@gmail.com': 'Vansh Shandilya',
        'aditya342me@gmail.com': 'Aditya Menon',
        'yaniyamalik@gmail.com': 'Yaniya',
        'dhi.grasanya@gmail.com': 'Rakshit Dhingra',
        'piyusharora1307@gmail.com': 'Piyush Arora',
        'sushmasrichanda03@gmail.com': 'Chanda Sushmasri',
        'daumyag@gmail.com': 'Daumya Gupta',
        'aayushgautam2502@gmail.com': 'Aayush Gautam',
        'prof.work.acc@gmail.com': 'Piyush Kheria',
        'puneetmalhotra6150@gmail.com': 'Puneet Malhotra',
        'huharshita@gmail.com': 'Harshita Upadhyay',
        'anzhongme@gmail.com': 'Devendra Neelam',
        'nlshkore@gmail.com': 'Nilesh Kore',
        'agrim.816@gmail.com': 'Agrim Sharma',
        'saharshsharma28@gmail.com': 'Saharsh Sharma',
        'college.studying23@gmail.com': 'Lavkush',
        'muditkhater99@gmail.com': 'Mudit Khater',
        'krishjatin02@gmail.com': 'Krish Gupta',
        'anmolmeetsingh010@gmail.com': 'Anmolmeet Singh',
        'h.e.m.a.n.t.xyzz@gmail.com': 'HEMANT',
        '24je0116@iitism.ac.in': 'Helem Thekkumvilayil Jose',
        'Ashish4422@proton.me': 'Ashish',
        'asthashukla0308@gmail.com': 'Astha Shukla',
        'shivenkumar22@gmail.com': 'Shiven Kumar Shandil',
        'tejathomas26@gmail.com': 'Teja Thomas',
        'mayankarora2302@gmail.com': 'Mayank Arora',
        'aditya.23gcebaids009@galgotiacollege.edu': 'Aditya Kumar',
        'singh.adityakumar8318@gmail.com': 'Aditya Kumar',
        'vansh20175014@gmail.com': 'Vansh Garg',
        '1rn21ai126.sujayd@gmail.com': 'SUJAY D',
        'Guptadivyansh357@gmail.com': 'Divyansh Gupta',
        'anilkumarxc03@gmail.com': 'Anil Kumar',
        'Nathan_G.P@protonmail.com': 'Nathan George Panathara',
        'Sunnydas5604@gmail.com': 'Debopriyo Das',
        'Faaa367810@gmail.com': 'Fatima',
        'dakshshastri72@gmail.com': 'Dakhs Shastri',

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696903',
      name: 'TechXcelerate 3.0 - Cyber Security',
      date: '2024-10-16',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733586/cybersec_bvuzgi.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        'anantikaasingh00@gmail.com': 'Anantika Singh Kushwaha',
        '9riday@gmail.com': 'Hriday Aggarwal',
        'asthashukla0308@gmail.com': 'Astha shukla',
        'samartharora2004@gmail.com': 'Samarth Arora',
        'kaurmanmeet2811@gmail.com': 'Manmeet Kaur',
        'nawazbiyawarwala@gmail.com': 'Nawaz Biyawarwala',
        'sumaiyahafiz7@gmail.com': 'Sumaya Hashim',
        'piyushkheria23@gmail.com': 'Piyush kheria',
        'a33507426@gmail.com': 'Aman',
        'vansh20005@gmail.com': 'Vansh Shandilya',
        'Aashi.mangla06@gmail.com': 'Aashi Mangla',
        'daumyag@gmail.com': 'Daumya Gupta',
        'chhavissjha@gmail.com': 'Chhavi Jha',
        'Ashish4422@proton.me': 'Ashish',
        'ratulmidya@gmail.com': 'Ratul Midya',
        'singh.adityakumar8318@gmail.com': 'Aditya Kumar',
        'nandinitulsian13@gmail.com': 'Nandini Tulsian',
        'himanshusahu260105@gmail.com': 'Himanshu sahu',
        'rbudhori04@gmail.com': 'Richa Budhori',
        'sharmaabhas.0803@gmail.com': 'ABHAS SHARMA',
        'bhardwajpriyanshi6@gmail.com': 'Priyanshi',
        'jainaadi333@gmail.com': 'Aaditya jain',
        'anshkumar5008@gmail.com': 'Ansh Kumar',
        'kavya2005additional@gmail.com': 'Kavya Chaudhari',
        'vaibhavkathait5974@gmail.com': 'Vaibhav Kathait',
        'tyagikanishka1864@gmail.com': 'Kanishka Tyagi',
        'adityaarya09@outlook.com': 'ADITYA KUMAR',
        'akanshalakhina3@gmail.com': 'Akansha',
        'jainaditya16106@gmail.com': 'Aditya Jain',
        'sonkarshashwat05@gmail.com': 'Shashwat Asar Sonkar',
        'aditya.23gcebaids009@galgotiacollege.edu': 'Aditya Kumar',
        'sandrarosaprince@gmail.com': 'Sandra Rosa Prince',
        'puneetmalhotra6150@gmail.com': 'Puneet Malhotra',
        'purohitanuj786@gmail.com': 'Anuj Purohit',
        'Sunnydas5604@gmail.com': 'Debopriyo Das',
        'itzabhinavgupta@gmail.com': 'Abhinav Gupta',
        'yaniyamalik@gmail.xom': 'Yaniya',
        'dhruvi.pcs@gmail.com': 'Dhruvi upadhyay',
        'kanwatanirudh@gmail.com': 'Anirudh Kanwat',
        'nibhendrad@gmail.com': 'Nibhendra Deo',
        'profakshayai@gmail.com': 'Akshay Pratap Singh',
        'work.anshikaj@gmail.com': 'ANSHIKA',
        'nikitajha10@gmail.com': 'Nikita Jha',
        'gauravjoshi7069@gmail.com': 'Gaurav Joshi',
        'sharmabhumika297@gmail.com': 'Bhumika sharma',
        'krishjatin02@gmail.com': 'Krish Gupta',
        'harishchandrashukla2006@gmail.com': 'Harish Chandra shukla',
        'divyanshverma4954@gmail.com': 'Divyansh Verma',
        'aryanshrivastav7374@gmail.com': 'Aryan Shrivastav',
        'anjalishukla3007@gmail.com': 'Anjali Shukla',
        '0241csai214@niet.co.in': 'Ambuj Bhatt',
        'prathamangra163@gmail.com': 'Pratham Angra',
        'krishgoyal180204@gmail.com': 'Krish Goyal',
        'Guptadivyansh357@gmail.com': 'Divyansh Gupta',
        'suyashg121@gmail.com': 'Suyash Gupta',
        'college.studying23@gmail.com': 'Lavkush',
        'anmolmeetsingh010@gmail.com': 'Anmolmeet Singh',
        'anshuljoshi1601@gmail.com': 'Anshul Joshi',
        '99krish.singla@gmail.com': 'Krish singla',
        'sushmasrichanda03@gmail.com': 'Chanda Sushmasri',
        'yashikag191220@gmail.com': 'Yashika',
        'kosangyadav2004@gmail.com': 'Kosang Yadav',
        'hiteshbhardwaj800@gmail.com': 'Hitesh Bhardwaj',
        'anilkumarxc03@gmail.com': 'Anil Kumar',
        'dakshshastri72@gmail.com': 'Daksh Shastri',
        'vansh20175014@gmail.com': 'Vansh Garg',
        'srishtisinghal6125@gmail.com': 'Srishti Singhal',
        'nehasinghjnv1011@gmail.com': 'NEHA SINGH',
        'vivekkumar843331@gmail.com': 'Vivek Kumar',
        'h.e.m.a.n.t.xyzz@gmail.com': 'HEMANT',
        '0241csmltwi010@niet.co.in': 'Anupam Singh',
        'eepsaranjan@gmail.com': 'Eepsa Ranjan',
        'yashikachaudhary849@gmail.com': 'Yashika Chaudhary',
        '1rn21ai126.sujayd@gmail.com': 'SUJAY D',
        'Aryanaggarwal1302@gmail.com': 'Aryan aggarwal',
        'muditkhater99@gmail.com': 'Mudit Khater',
        'agrim.816@gmail.com': 'Agrim Sharma',

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696904',
      name: 'TechXcelerate 3.0 - DSA and CP',
      date: '2024-10-17',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733587/dsacp_tdtcze.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        'itzabhinavgupta@gmail.com': 'Abhinav Gupta',
        'saharshsharma28@gmail.com': 'Saharsh Sharma',
        'anjalishukla3007@gmail.com': 'Anjali Shukla',
        '99krish.singla@gmail.com': 'Krish',
        'daumyag@gmail.com': 'Daumya Gupta',
        'deepanshukashyap8299@gmail.com': 'DEEPANSHU KASHYAP',
        'chiragk1412@gmail.com': 'Chirag Kashyap',
        'samartharora2004@gmail.com': 'Samarth Arora',
        'jainaditya16106@gmail.com': 'Aditya Jain',
        'anantikaasingh00@gmail.com': 'Anantika Singh Kushwaha',
        'srishtisinghal6125@gmail.com': 'Srishti Singhal',
        'rbudhori04@gmail.com': 'Richa Budhori',
        'nlshkore@gmail.com': 'Nilesh Kore',
        'profakshayai@gmail.com': 'Akshay Pratap Singh',
        'khabart590@gmail.com': 'Krishna Porwal',
        'chhavissjha@gmail.com': 'Chhavi Jha',
        'nikitajha10a@gmail.com': 'Nikita Jha',
        'work.anshikaj@gmail.com': 'ANSHIKA',
        'nakulgupta0152@gmail.com': 'Nakul Gupta',
        'prajapatisadhna7513@gmail.com': 'Sadhna',
        'khushi.s.222613@gmail.com': 'Khushi Singh',
        'janhvi571@gmail.com': 'Janhvi',
        'anilkumarxc03@gmail.com': 'Anil Kumar',
        'sandrarosaprince@gmail.com': 'Sandra Rosa Prince',
        'sushmasrichanda03@gmail.com': 'Chanda Sushmasri',
        'mayankg88813@gmail.com': 'Mayank Gupta',
        'sarthakkr00005@gmail.com': 'Sarthak Kumar',
        'sharma': 'Bhumika',
        'dhruvi.pcs@gmail.com': 'Dhruvi Upadhyay',
        'Sunnydas5604@gmail.com': 'Debopriyo Das',
        'aditya342me@gmail.com': 'Aditya Menon',
        '0241csmltwi010@niet.co.in': 'Anupam Singh',
        'prof.work.acc@gmail.com': 'Piyush Kheria',
        'muditkhater99@gmail.com': 'Mudit Khater',
        'sohaibaamir00@gmail.com': 'Sohaib Aamir',
        'harshdorle6@gmail.com': 'Swarwel Dorle',
        'kavya2005additional@gmail.com': 'Kavya Chaudhari',
        'Guptadivyansh357@gmail.com': 'Divyansh Gupta',
        'gouravtandan56@gmail.com': 'Gourav Tandan',
        'kingarjun74280@gmail.com': 'Arjun',
        'prathamangra163@gmail.com': 'Pratham Angra',
        'madsharma0022@gmail.com': 'Madhav Sharma',
        'ekhaliq409@gmail.com': 'Eman Khaliq',
        'mishra.akanksha535@gmail.com': 'Akanksha Kumari Mishra',
        'vansh20005@gmail.com': 'Vansh Shandilya',
        'earthianprithviwasi8888@gmail.com': 'Earthian Prithviwasi',
        'sonkarshashwat05@gmail.com': 'Shashwat Asar Sonkar',
        'Krishgoyal180204@gmail.com': 'Krish Goyal',
        'agrim.816@gmail.com': 'Agrim Sharma',
        'sumaiyahafiz7@gmail.com': 'Sumaya Hashim',
        'suyashg121@gmail.com': 'Suyash Gupta',
        'kosangyadav2004@gmail.com': 'Kosang Yadav',
        'Soumya31k@gmail.com': 'Soumya Kumar',
        'surajdwivedi2140@gmail.com': 'Suraj Dwivedi',
        'aryanshrivastav7374@gmail.com': 'Aryan Shrivastav',
        '1rn21ai126.sujayd@gmail.com': 'SUJAY D',
        'h.e.m.a.n.t.xyzz@gmail.com': 'HEMANT',
        'rathordisha62@gmail.com': 'Disha Rathore',
        'negi.sakshi9018@gmail.com': 'Sakshi Negi',
        '24je0116@iitism.ac.in': 'Helem Thekkumvilayil Jose',
        'harishchandrashukla2006@gmail.com': 'Harish Chandra Shukla',
        'sparshaggarwal0308@gmail.com': 'Sparsh Aggarwal',
        'kaurmanmeet2811@gmail.com': 'Manmeet Kaur',
        'krishjatin02@gmail.com': 'Krish Gupta',
        'hiteshbhardwaj800@gmail.com': 'Hitesh Bhardwaj',
        'vansh20175014@gmail.com': 'Vansh Garg',

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696905',
      name: 'TechXcelerate 3.0 - App Development',
      date: '2024-10-18',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733584/appdev_imhcov.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        "rbudhori04@gmail.com": "Richa Budhori",
        "itzabhinavgupta@gmail.com": "Abhinav Gupta",
        "khabart590@gmail.com": "Krishna porwal",
        "asthashukla0308@gmail.com": "Astha shukla",
        "samartharora2004@gmail.com": "Samarth Arora",
        "sumaiyahafiz7@gmail.com": "Sumaya Hashim",
        "chhavissjha@gmail.com": "Chhavi Jha",
        "kanwatanirudh@gmail.com": "Anirudh Kanwat",
        "jainaditya16106@gmail.com": "Aditya Jain",
        "anjalishukla3007@gmail.com": "Anjali Shukla",
        "work.anshikaj@gmail.com": "ANSHIKA",
        "suyashg121@gmail.com": "Suyash Gupta",
        "muditkhater99@gmail.com": "Mudit Khater",
        "anantikaasingh00@gmail.com": "Anantika Singh Kushwaha",
        "kaurmanmeet2811@gmail.com": "Manmeet Kaur",
        "prathamehra2003@gmail.com": "Pratham",
        "Guptadivyansh357@gmail.com": "Divyansh Gupta",
        "adityatiwari4125@gmail.com": "Aditya Tiwari",
        "sharmabhumika297@gmail.com": "bhumika sharma",
        "deepanshukashyap8299@gmail.com": "DEEPANSHU KASHYAP",
        "99krish.singla@gmail.com": "Krish",
        "ps451791@gmail.com": "Parth sharma",
        "eepsaranjan@gmail.com": "Eepsa Ranjan",
        "anilkumarxc03@gmail.com": "Anil Kumar",
        "akanshalakhina3@gmail.com": "Akansha",
        "prathamangra163@gmail.com": "Pratham Angra",
        "h.e.m.a.n.t.xyzz@gmail.com": "HEMANT",
        "college.studying23@gmail.com": "Lavkush",
        "prathamehra2003@gmail.com": "Pratham",
        "yashikachaudhary849@gmail.com": "Yashika chaudhary",
        "amanverdiya06@gmail.com": "Aman",
        "riya.m5780@gmail.com": "Riya Mehta",
        "jainsomya184@gmail.com": "Somya Jain",
        "srishtisinghal6125@gmail.com": "Srishti Singhal",
        "chiragk1412@gmail.com": "Chirag Kashyap",
        "Vaibhavkathait5974@gmail.com": "Vaibhav Kathait",
        "Krishgoyal180204@gmail.com": "Krish Goyal",
        "sushmasrichanda03@gmail.com": "Chanda Sushmasri",
        "1rn21ai126.sujayd@gmail.com": "SUJAY D",
        "0241csmltwi010@niet.co.in": "Anupam Singh",
        "adityatiwari4125@gmail.com": "Aditya Tiwari",
        "madsharma0022@gmail.com": "Madhav Sharma",
        "nlshkore@gmail.com": "Nilesh Kore",
        "kmrsumit509@gmail.com": "Sumit kumar",
        "avantikapadhi4822@gmail.com": "Avantika Padhi",
        "Sunnydas5604@gmail.com": "Debopriyo Das",
        "aryanshrivastav7374@gmail.com": "Aryan Shrivastav",
        "agrim.816@gmail.com": "Agrim Sharma",
        "Devanshsingla9@gmail.com": "Devansh singla",
        "krishjatin02@gmail.com": "Krish Gupta",
        "khushi.s.222613@gmail.com": "Khushi Singh",
        "anmolmeetsingh010@gmail.com": "Anmolmeet Singh",
        "arnavbhardwaj111@gmail.com": "Arnav Bhardwaj",
        "vansh20175014@gmail.com": "Vansh Garg",

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696906',
      name: 'TechXcelerate 3.0 - Blockchain',
      date: '2024-10-20',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733584/blockchain_klqfvl.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        "anantikassingh00@gmail.com": "Anantika Singh Kushwaha",
        "samartharora2004@gmail.com": "Samarth Arora",
        "jainaditya16106@gmail.com": "Aditya Jain",
        "aanyagoyal45@gmail.com": "Aanyaa",
        "nikitajha10a@gmail.com": "NIKITA JHA",
        "piyusharora1307@gmail.com": "Piyush Arora",
        "college.studying23@gmail.com": "Lavkush",
        "rbudhori04@gmail.com": "Richa Budhori",
        "anilkumarxc03@gmail.com": "Anil Kumar",
        "srishtisinghal6125@gmail.com": "Srishti Singhal",
        "0241csmltwi010@niet.co.in": "Anupam Singh",
        "prathamangra163@gmail.com": "Pratham Angra",
        "sushmasrichanda03@gmail.com": "Chanda Sushmasri",
        "dhruvi.pcs@gmail.com": "Dhruvi Upadhyay",
        "mansisinghal623@gmail.com": "Mansi",
        "krishgoyal180204@gmail.com": "Krish Goyal",
        "yashikachaudhary849@gmail.com": "Yashika chaudhary",
        "eepsaranjan@gmail.com": "Eepsa Ranjan",
        "99krish.singla@gmail.com": "Krish",
        "Adityatiwari4125@gmail.com": "Aditya Tiwari",
        "h.e.m.a.n.t.xyzz@gmail.com": "Hemant",
        "arushkarnatak1881@gmail.com": "Arush Karnatak",
        "Sunnydas5604@gmail.com": "Debopriyo Das",
        "shvethvanhan37@gmail.com": "Jitaditya Chakraborty",
        "muditkhater99@gmail.com": "Mudit Khater",
        "work.anshikaj@gmail.com": "ANSHIKA",
        "madsharma0022@gmail.com": "Madhav Sharma",
        "nlshkore@gmail.com": "Nilesh Kore",
        "0241csai@niet.co.in": "Ambuj Bhatt",
        "khushi.s.222613@gmail.com": "Khushi Singh",
        "Guptadivyansh357@gmail.com": "Divyansh Gupta",
        "kaushik.akshita217@gmail.com": "AKSHITA KAUSHIK",
        "hiteshbhardwaj800@gmail.com": "Hitesh Bhardwaj",
        "profakshayai@gmail.com": "Akshay Pratap Singh",
        "prathamehra2003@gmail.com": "Pratham mehra",
        "kaurmanmeet2811@gmail.com": "Manmeet Kaur",
        "deepanshukashyap8299@gmail.com": "Deepanshu kashyap",
        "aryanshrivastav7374@gmail.com": "Aryan Shrivastav",
        "vansh20175014@gmail.com": "Vansh Garg",
        "asthashukla0308@gmail.com": "Astha Shukla",
        "srishtisinghal6125@gmail.com": "Srishti Singhal",
        "asthashukla0308@gmail.com": "Astha shukla",

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696907',
      name: 'TechXcelerate 3.0 - AR/VR',
      date: '2024-10-21',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733585/arvr_jelhma.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        "aakritiarya2005@gmail.com": "Aakriti Arya",
        "kaurmanmeet2811@gmail.com": "Manmeet Kaur",
        "samartharora2004@gmail.com": "Samarth Arora",
        "sushmasrichanda03@gmail.com": "Chanda Sushmasri",
        "akanshalakhina23@gmail.com": "Akansha",
        "anantikaasingh00@gmail.com": "Anantika Singh Kushwaha",
        "deepanshukashyap8299@gmail.com": "DEEPANSHU KASHYAP",
        "muditkhater99@gmail.com": "Mudit Khater",
        "work.anshikaj@gmail.com": "ANSHIKA",
        "janhvi571@gmail.com": "Janhvi",
        "rbudhori04@gmail.com": "Richa Budhori",
        "prathamangra163@gmail.com": "Pratham Angra",
        "chhavissjha@gmail.com": "Chhavi Jha",
        "vaibhavkathait5974@gmail.com": "Vaibhav Kathait",
        "jainaditya16106@gmail.com": "Aditya Jain",
        "adityatiwari4125@gmail.com": "Aditya Tiwari",
        "college.studying23@gmail.com": "Lavkush",
        "khushi.s.222613@gmail.com": "Khushi Singh",
        "riya.m5780@gmail.com": "Riya mehta",
        "yashikachaudhary849@gmail.com": "Yashika chaudhary",
        "suyashg121@gmail.com": "Suyash Gupta",
        "1rn21ai126.sujayd@gmail.com": "SUJAY D",
        "agrim.816@gmail.com": "Agrim Sharma",
        "dhruvsh5467@gmail.com": "Dhruv Sharma",
        "mansisinghal623@gmail.com": "Mansi",
        "prathamehra2003@gmail.com": "Pratham mehra",
        "profakshayai@gmail.com": "Akshay Pratap Singh",
        "eepsaranjan@gmail.com": "Eepsa Ranjan",
        "mohammedsadhtoo@gmail.com": "Mohammed Sadh",
        "sunnydas5604@gmail.com": "Debopriyo Das",
        "itzabhinavgupta@gmail.com": "Abhinav Gupta",
        "srishtisinghal6125@gmail.com": "Srishti Singhal",
        "vansh20175014@gmail.com": "Vansh Garg",
        "aryanshrivastav7374@gmail.com": "Aryan Shrivastav",
        "hiteshbhardwaj800@gmail.com": "Hitesh Bhardwaj",
        "sunnydas5604@gmail.com": "Debopriyo Das",

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696908',
      name: 'TechXcelerate 3.0 - Cloud and DevOps',
      date: '2024-10-22',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733587/cloud_rrra6x.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        "prathamangra163@gmail.com": "Pratham Angra",
        "aakritiarya2005@gmail.com": "Aakriti Arya",
        "9riday@gmail.com": "Hriday Aggarwal",
        "samartharora2004@gmail.com": "Samarth Arora",
        "khushi.s.222613@gmail.com": "Khushi Singh",
        "bhardwajpriyanshi6@gmail.com": "Priyanshi",
        "himanshiagg18@gmail.com": "Himanshi Aggarwal",
        "vansh20005@gmail.com": "Vansh Shandilya",
        "work.anshikaj@gmail.com": "ANSHIKA",
        "sushmasrichanda03@gmail.com": "Chanda Sushmasri",
        "rbudhori04@gmail.com": "Richa Budhori",
        "anilkumarxc03@gmail.com": "Anil Kumar",
        "0241csmltwi010@niet.co.i": "Anupam Singh",
        "madsharma0022@gmail.com": "Madhav Sharma",
        "2024pietcsbhumika037@poornima.org": "Bhumika sharma",
        "sunnydas5604@gmail.com": "Debopriyo Das",
        "piyushkheria.cs23@rvce.edu.in": "Piyush kheria",
        "Manikhippal@hotmail.com": "Manik Hippalgaonkar",
        "suhas4497@gmail.com": "Suhas N H",
        "chiragk1412@gmail.com": "Chirag Kashyap",
        "deepanshukashyap8299@gmail.com": "DEEPANSHU KASHYAP",
        "hiteshbhardwaj800@gmail.com": "Hitesh Bhardwaj",
        "agrim.816@gmail.com": "Agrim Sharma",
        "0241csai214@niet.co.in": "Ambuj Bhatt",
        "1rn21ai126.sujayd@gmail.com": "SUJAY D",
        "nibedanpati@gmail.com": "Nibedan Pati",
        "avantikapadhi4822@gmail.com": "Avantika Padhi",
        "muditkhater99@gmail.com": "Mudit Khater",
        "chhavissjha@gmail.com": "Chhavi Jha",
        "anantikaasingh00@gmail.com": "Anantika Singh Kushwaha",
        "anmolmeetsingh010@gmail.com": "Anmolmeet Singh",
        "asmitguptavat1@gmail.com": "Asmit Gupta",
        "adityatiwari4125@gmail.com": "Aditya Tiwari",
        "prathamehra2003@gmail.com": "Pratham mehra",
        "nlshkore@gmail.com": "Nilesh Kore",
        "kaurmanmeet2811@gmail.com": "Manmeet Kaur",

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
    {
      id: '696909',
      name: 'TechXcelerate 3.0 - Open Source',
      date: '2024-10-26',
      templateImage: 'https://res.cloudinary.com/df5hitkbj/image/upload/v1731733587/opensource_skno5u.png',
      namePosition: {
        x: 1000,
        y: 900,
        fontSize: 100,
        fontColor: '#ffffff'
      },
      qrPosition: {
        x: 65,  // Bottom right corner positioning
        y: 1010,
        size: 150  // Size of QR code in pixels
      },
      registeredEmails: {
        'chutiyabanayatumko@gmail.com': 'Apna naam toh batao',
        "prathamangra163@gmail.com": "Pratham Angra",
        "suyashg121@gmail.com": "Suyash Gupta",
        "9riday@gmail.com": "Hriday Aggarwal",
        "nawazbiyawarwala@gmail.com": "Nawaz Biyawarwala",
        "samartharora2004@gmail.com": "Samarth Arora",
        "priyanshg433@gmail.com": "Priyansh Garg",
        "santanuhaldar175@gmail.com": "Santanu Haldar",
        "deepanshukashyap8299@gmail.com": "DEEPANSHU KASHYAP",
        "sushmasrichanda03@gmail.com": "Chanda Sushmasri",
        "madsharma0022@gmail.com": "Madhav Sharma",
        "Sharma": "Bhumika Sharma",
        "aliahaider149@gmail.com": "Alia Haider",
        "chhavissjha@gmail.com": "Chhavi Jha",
        "rakshitgoyal770@gmail.com": "Rakshit Goyal",
        "janhvi571@gmail.com": "Janhvi",
        "vaibhavkathait5974@gmail.com": "Vaibhav Kathait",
        "sandrarosaprince@gmail.com": "Sandra Rosa Prince",
        "muditkhater99@gmail.com": "Mudit Khater",
        "anantikassingh00@gmail.com": "Anantika Singh Kushwaha",
        "himanshusahu260105@gmail.com": "Himanshu Sahi",
        "aakritiarya2005@gmail.com": "Aakriti Arya",
        "mayankg88813@gmail.com": "Mayank Gupta",
        "prathamehra2003@gmail.com": "Pratham Mehra",
        "hardikbindal12@gmail.com": "Hardik Bindal",
        "srishtisinghal6125@gmail.com": "Srishti Singhal",
        "sumaiyahafiz7@gmail.com": "Sumaya Hashim",
        "gk80942214@gmail.com": "Keshav Gupta",
        "agrim.816@gmail.com": "Agrim Sharma",
        "khushi.s.222613@gmail.com": "Khushi Singh",
        "avantikapadhi4822@gmail.com": "Avantika Padhi",
        "ekhaliq409@gmail.com": "Eman Khaliq",
        "kaurmanmeet2811@gmail.com": "Manmeet Kaur",
        "aryanshrivastav7374@gmail.com": "Aryan Shrivastav",
        "sunnydas5604@gmail.com": "Debopriyo Das",
        "hiteshbhardwaj800@gmail.com": "Hitesh Bhardwaj",

        //dummyones
        '2022pcecytanishq057@poornima.org': 'TANISHQ AGARWAL',
        'aabhashtripathi53@gmail.com': 'Aabhash Tripathi',
        'aadishjain017@gmail.com': 'Aadish Jain',
        'aashishk4568@gmail.com': 'AASHISH KUMAR',
        'aashishwadhwa1230@gmail.com': 'Ashish Wadhwa',
        'abhayy.sharma.08@gmail.com': 'Abhay Sharma',
        'abhinashkumar03619@gmail.com': 'Abhinesh Kumar',
        'abhishekkumar21229@gmail.com': 'Abhishek Parihar',
        'aditya892003@gmail.com': 'Aditya Gupta',
        'agrima.ag126@gmail.com': 'Agrima Agarwal',
        'aishanirajpal@gmail.com': 'Aishani Rajpal',
        'ajaish303@gmail.com': 'AKANSH JAIN',
        'akarshverma187@gmail.com': 'Akarsh Verma',
        'amitkalita942@gmail.com': 'Amit Kalita',
        'anandkrchaudhary25@gmail.com': 'Anand Kumar Chaudhary',
        'anandmohanjha241@gmail.com': 'Anand Mohan Jha',
        'aniketsingh1472@gmail.com': 'Aniket Kumar',
        'ankurk2684@gmail.com': 'Ankur Kumar',
        'anushka30singh07@gmail.com': 'Anushka Singh',
        'aryansharma.pvt@gmail.com': 'Aryan Sharma',
        'bhadula245@gmail.com': 'Akhilesh Bhadula',
        'bhardwajniharika40@gmail.com': 'Niharika Bhardwaj',
        'chitransh.pal108@gmail.com': 'Chitransh Pal',
        'choudharyjunaid144@gmail.com': 'Junaid Choudhary',
        'codeque7@gmail.com': 'Samarth Jain',
        'daksh2003sardana@gmail.com': 'Daksh Sardana',
        'daxh31932@gmail.com': 'Daxh Khatreja',
        'deepanshuaggarwal8871@gmail.com': 'DEEPANSHU',
        'devansh.dwivedi69@gmail.com': 'Devansh dwivedi',
        'dhananjayaggarwal6561@gmail.com': 'Dhananjay Aggarwal',
        'dhruv18gaba@gmail.com': 'DHRUV GABA',
        'dhruvgupta0908@gmail.com': 'Dhruv Gupta',
        'dikshadhasmana230204@gmail.com': 'Diksha Sharma',
        'dixitpranav24@gmail.com': 'PRANAV DIXIT',
        'edu.ngarg@gmail.com': 'Naman Garg',
        'faizqureshi6126@gmail.com': 'Faizuddin',
        'gargm0068@gmail.com': 'Mokshit',
        'gauranshgoel04@gmail.com': 'Gauransh Goel',
        'gauribehl02@gmail.com': 'Gauri Behl',
        'gouravsingh4495@gmail.com': 'GOURAV SINGH',
        'govindpal2003@gmail.com': 'GOVIND SINGH',
        'gracydhamija7@gmail.com': 'Gracy Dhamija',
        'guptaashutosh502@gmail.com': 'Ashutosh Gupta',
        'guptankit.2003@gmail.com': 'Ankit Gupta',
        'guptraj15@gmail.com': 'RAJAT',
        'hardikbhardwaj1810@gmail.com': 'HARDIK BHARDWAJ',
        'harsh24042008@gmail.com': 'ABHAY ARORA',
        'harshchoudhary2805@gmail.com': 'Harsh Choudhary',
        'harshitsachdeva291104@gmail.com': 'Harshit Sachdeva',
        'hsharshsharma370@gmail.com': 'Harsh Sharma',
        'immayank249@gmail.com': 'Mayank Gupta',
        'influencerop007@gmail.com': 'Surender',
        'ishikasinghal2642005@gmail.com': 'Ishika Singhal',
        'jain.rajat.2574@gmail.com': 'Rajat Jain',
        'jain.yash20082004@gmail.com': 'Yash Jain',
        'jain12devanshi@gmail.com': 'Devanshi Jain',
        'jaspreetsingh11b21@gmail.com': 'jaspreet singh',
        'jatingera2004@gmail.com': 'Jatin Gera',
        'jha29manish@gmail.com': 'Manish Jha',
        'jharishi2025@gmail.com': 'Rishi',
        'jharupesh675@gmail.com': 'Rupesh Kamar Jha',
        'jiveshkoli15@gmail.com': 'Jivesh',
        'joshiyogesh2727@gmail.com': 'Yogesh Joshi',
        'jyotiahuja822@gmail.com': 'Jyoti',
        'karanjoshi901@gmail.com': 'Karan Joshi',
        'kartikjain068@gmail.com': 'Kartik Jain',
        'kaushalrai8076958922@gmail.com': 'Kaushal Rai',
        'kaushikmithas2409@gmail.com': 'Mithas kaushik',
        'khandelwalmanan05@gmail.com': 'Manan Khandelwal',
        'khanna.niharika09@gmail.com': 'Niharika Khanna',
        'kshitizmanral@gmail.com': 'KSHITIZ MANRAL',
        'kunalkaku2002@gmail.com': 'Kunal',
        'kundujayant007@gmail.com': 'Jayant Kundu',
        'kusum2006verma@gmail.com': 'Aayushi Verma',
        'lakshaygarg2810@gmail.com': 'Lakshay',
        'lavanshibansal29@gmail.com': 'Lavanshi Bansal',
        'lovelypandey2427@gmail.com': 'Lovely Pandey',
        'lslover034@gmail.com': 'Lovepreet singh',
        'madhurgupta05march@gmail.com': 'Madhur Gupta',
        'manjeetkumarskills@gmail.com': 'Manjeet Kumar',
        'manyabarua@gmail.com': 'Manya Barua',
        'megh.darshmangal@gmail.com': 'MEGH MANGAL',
        'modiagam975@gmail.com': 'Agamveer Singh Modi',
        'mohit16sharma17@gmail.com': 'Mohit Sharma',
        'mohitgusain8671@gmail.com': 'Mohit Gusain',
        'muditakhar11@gmail.com': 'Mudita Khar',
        'nachiketanag06@rediffmail.com': 'Nachiketa',
        'nadeemanas617@gmail.com': 'Anas',
        'namanjain8586010414@gmail.com': 'Naman jain',
        'navyaajainn04@gmail.com': 'Navya fulfagar',
        'neelamgupta4521@gmail.com': 'Neha',
        'nehaprsd08@gmail.com': 'Neha',
        'nikhilkr2604@gmail.com': 'Nikhil Kumar',
        'nikhilmendiratta2003@gmail.com': 'Nikhil Mendiratta',
        'nimeshtripathi2004@gmail.com': 'Nimesh',
        'nishatirkey0311@gmail.com': 'Nisha Tirkey',
        'nktripathi201@gmail.com': 'Nitin Kumar Tripathi',
        'oraonatula@gmail.com': 'Atul Oraon',
        'parth.nepalia@gmail.com': 'Parth Nepalia',
        'pbarthwal90@gmail.com': 'Pranav',
        'prathammittal355@gmail.com': 'Pratham Mittal',
        'prernajha267@gmail.com': 'Prerna Jha',
        'psrcofficial10@gmail.com': 'Pradeepto Sarkar',
        'rajatmangla0203@gmail.com': 'Rajat Mangla',
        'rajtanwar538400@gmail.com': 'Raj Tanwar',
        'rajvermasja18@gmail.com': 'Raj verma',
        'rathoreharshkv@gmail.com': 'Harsh Singh rathore',
        'rawatsonia909@gmail.com': 'Sonia Rawat',
        'raysarkar75@gmail.com': 'Pradeepto Sarkar',
        'realvandanasharma18@gmail.com': 'Vandana Sharma',
        'renamsingla@gmail.com': 'Renam',
        'riteshm2710@gmail.com': 'RITESH MISHRA',
        'rk1singh001@gmail.com': 'Raushan Kumar Singh',
        'rupeshsinha8743@gmail.com': 'Rupesh Kumar Sinha',
        's.singh92780@gmail.com': 'Sonu',
        'sachinnain439@gmail.com': 'Sachin Kumar',
        'sahil.mega360@gmail.com': 'Sahil Starr',
        'sahilchauhan0603@gmail.com': 'Sahil Chauhan',
        'sakshibhardwaj14@gmail.com': 'Sanchita Bhardwaj',
        'samg88378@gmail.com': 'Bhanu Goel',
        'sanjeevkr12212@gmail.com': 'Sanjeev Kumar',
        'sarthakaloria27@gmail.com': 'Sarthak Aloria',
        'saumyajhamb.12@gmail.com': 'Saumya',
        'sharanyaaggarwal01@gmail.com': 'Sharanya Aggarwal',
        'shauryaupmanyu1@gmail.com': 'Shaurya Upmanyu',
        'shivankkapur2004@gmail.com': 'Shivank Kapur',
        'shreyashbarca10@gmail.com': 'Shreyash singh chauhan',
        'shrishti.mishra3110@gmail.com': 'Shrishti Mishra',
        'shroffakshit@gmail.com': 'Akshit Shroff',
        'shubham.aa846@gmail.com': 'Shubham Singh',
        'simranbandhu719@gmail.com': 'Simran Bandhu',
        'singh.chinmay345@gmail.com': 'Chinmay Singh',
        'singharinav69@gmail.com': 'Arnav Singh',
        'sshruti4824@gmail.com': 'SHRUTI',
        'sujalgupta1412@gmail.com': 'Sujal Gupta',
        'sumit.joshi0616@gmail.com': 'Sumit joshi',
        'surajgpes@gmail.com': 'Suraj kumar',
        'survashi724@gmail.com': 'Urvashi Sharma',
        'swarajkumar830@gmail.com': 'Swaraj kumar',
        'tanay.jha2004@gmail.com': 'Tanay Kumar',
        'tanishsaini26@gmail.com': 'TANISH Saini',
        'tanmay631@gmail.com': 'Tanmay',
        'tanyasingh19072002@gmail.com': 'Tanya singh',
        'theduke4865@gmail.com': 'Yash Kumar',
        'tithij01@gmail.com': 'Tithi jain',
        'tushar01092004@gmail.com': 'Tushar Adhikari',
        'tusharrathorlogan@gmail.com': 'Tushar Rathor',
        'tyagi28sahil@gmail.com': 'Sahil',
        'tyagivedant3846@gmail.com': 'Vedant Tyagi',
        'udaybansal94@gmail.com': 'Uday bansal',
        'vaibhavbhardwaj0400@gmail.com': 'Vaibhav',
        'vermaprem.pm@gmail.com': 'Prem Verma',
        'vikas1969awz@gmail.com': 'Vikas',
        'vivek70489@gmail.com': 'Vivek',
        'yuvikakathaith3103@gmail.com': 'Yuvika Kathaith',
      }
    },
  ],

  addEvent: (event) => set((state) => ({ events: [...state.events, event] })),

  getNameByEmail: (eventId, email) => {
    const event = get().events.find((e) => e.id === eventId);
    return event && event.registeredEmails[email] ? event.registeredEmails[email] : false;
  },
}));